.tabbar-container {
    width: 100%; /* 设置容器宽度 */
    overflow: auto; /* 显示滚动条，根据内容决定是否显示 */
  
    /* 隐藏默认的滚动条样式 */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }

.tabbar-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }