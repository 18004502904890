* {
  margin:0;
  padding: 0;
  border:0px;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;border:0px;
}

h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}



.PlanPage {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.markerword {
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 8px;
}
/* 啊这是分界线 */
.amap-logo {
  display: none!important;
  visibility: hidden!important;
}
 
.amap-copyright {
  display: none!important;
  visibility: hidden!important;
}
